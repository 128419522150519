import { Component } from "react"
import { navigate } from "gatsby"

class Reload2Page extends Component {
    
    componentDidMount = async() => {
        navigate("/agreement1")
    }
    render() {
        return ('');
    }
}

export default Reload2Page;
